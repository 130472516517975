import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { style: { "overflow-x": "auto" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Line = _resolveComponent("Line");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            style: _normalizeStyle([{ "position": "relative" }, {
                    width: _ctx.monthCount && _ctx.monthCount > 1 ? 100 * _ctx.monthCount + '%' : '100%'
                }])
        }, [
            _createVNode(_component_Line, {
                options: _ctx.chartOptions,
                data: _ctx.chartData,
                style: { "height": "858px" }
            }, null, 8, ["options", "data"])
        ], 4)
    ]));
}
