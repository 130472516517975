import { domen } from '@/services/fetch';
import * as signalR from '@microsoft/signalr';
const url = domen + 'onlineusershub';
const online = new signalR.HubConnectionBuilder()
    .withUrl(url, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets
})
    .build();
const id = Number(localStorage.getItem('id'));
export default {
    async start() {
        try {
            await online.start();
        }
        catch (error) {
            console.error(error);
        }
    },
    connectedUsers(callback) {
        online.on('UserConnected', callback);
    },
    disconnectedUsers(callback) {
        online.on('UserDisconnected', callback);
    },
    connected() {
        if (id) {
            online.invoke('UserConnected', id);
        }
    }
};
