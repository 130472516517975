import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_header_component = _resolveComponent("header-component");
    const _component_router_view = _resolveComponent("router-view");
    const _component_footer_component = _resolveComponent("footer-component");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_header_component),
            _createElementVNode("main", null, [
                _createVNode(_component_router_view)
            ])
        ]),
        _createVNode(_component_footer_component)
    ], 64));
}
