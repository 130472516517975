import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d02926fe"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "notification_container" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "notification_window",
                key: notification.timerId
            }, [
                _createElementVNode("button", {
                    class: "notification_close",
                    onClick: ($event) => (_ctx.closeNotification(index))
                }, null, 8, _hoisted_2),
                _createElementVNode("div", null, [
                    _createElementVNode("h4", null, _toDisplayString(notification.message), 1),
                    (notification.desc)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(notification.desc), 1))
                        : _createCommentVNode("", true)
                ])
            ]));
        }), 128))
    ]));
}
