import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-60be20a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "modal_section"
};
const _hoisted_2 = { class: "modal_window" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                    class: "modal_close",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
                }),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _createElementVNode("div", {
                class: "modal_back",
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
            })
        ]))
        : _createCommentVNode("", true);
}
