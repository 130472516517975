import { createApp, reactive, ref } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { getBalance } from '@/services/fetch';
import UI from '@/components/UI';
const app = createApp(App);
UI.forEach(element => {
    if (element.name) {
        app.component(element.name, element);
    }
});
const user = reactive({
    logged: ref(false),
    balance: ref(0),
    name: ref(''),
    id: ref(0),
    token: ref('')
});
const name = localStorage.getItem('name');
const id = localStorage.getItem('id');
const token = localStorage.getItem('token');
if (name && id && token) {
    user.logged = true;
    user.name = name;
    user.id = Number(id);
    user.token = token;
    getBalance(user.logged).then(balance => {
        if (balance !== false) {
            user.balance = Number(balance);
        }
    });
}
const appAttr = reactive({
    isPhone: ref(window.innerWidth <= 760),
    isTab: ref(window.innerWidth <= 990),
    isPC: ref(window.innerWidth <= 1280)
});
app.config.globalProperties.$appAttr = appAttr;
app.config.globalProperties.$user = user;
router.beforeEach((to) => {
    if (!user.logged && (to.name === 'referal' || to.name === 'bonus' || to.name === 'settings')) {
        return '/';
    }
});
app.use(store).use(router).mount('#app');
