import { domen } from '@/services/fetch';
import * as signalR from '@microsoft/signalr';
const url = domen + 'horseGameBetsHub';
const lastGames = new signalR.HubConnectionBuilder()
    .withUrl(url, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets
})
    .build();
export default {
    async start() {
        try {
            await lastGames.start();
        }
        catch (error) {
            console.error(error);
        }
    },
    getHorseGameBet(callback) {
        lastGames.on('ReceiveMessage', callback);
    },
    stopConnection() {
        lastGames.stop();
    }
};
